import {
    DASHBOARD,
    ISDISCONNECT,
    ISLOADING
} from '../constants';
import * as dashboard from './Dashboard/dashboard.action';


export const callAPI = (actionName, functionName, paramObject = null, name = "", message = "") => {
    return async dispatch => {
        dispatch(isLoading(true))

        switch (actionName) {
            case DASHBOARD:
                return dispatch(dashboard[functionName](paramObject)).then(res => {
                    dispatch(isLoading(false))
                    dispatch(checkIsDisconnect(res, !res ? name : '', !res ? message : ''))
                    return res
                })
            default:
                dispatch(isLoading(false))
        }
    }
}


export const callbackAPI = (actionName, functionName, paramObject) => {
    return async dispatch => {
        dispatch(isLoading(true))
        switch (actionName) {
            case DASHBOARD:
                return await dashboard[functionName](paramObject).then(res => {
                    dispatch(isLoading(false))
                    dispatch(checkIsDisconnect(res))
                    return res
                })

            default: return null;
        }
    }
}

export const checkIsDisconnect = (res, name = "", message = "") => {
    return dispatch => {

        if (res === false)
            dispatch(isDisconnect(true, name, message))
        else
            dispatch(isDisconnect(false))
    }
}

export const isLoading = state => {
    return {
        type: ISLOADING,
        state
    }
}

export const isDisconnect = (state = false, name = "", message = "") => {
    return {
        type: ISDISCONNECT,
        state,
        name,
        message
    }
}
