
export const FETCHING_DATA = 'FETCHING_DATA'
export const FETCHING_DATA_SUCCESS = 'FETCHING_DATA_SUCCESS'
export const FETCHING_DATA_FAILURE = 'FETCHING_DATA_FAILURE'
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS'
export const SET_LOGIN = 'SET_LOGIN'

//
export const ISDISCONNECT = 'ISDISCONNECT'
export const ISLOADING = 'ISLOADING'

// Page Constant
export const DASHBOARD = 'DASHBOARD'
export const REGISTER = 'REGISTER'

//
export const FORM = "FORM"
export const SERVICE = "SERVICE"
export const POLICY = "POLICY"