import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  Input,
  Button,
  Card,
  CustomInput,
  Modal,
  ModalBody,
  Label,
  UncontrolledCollapse,
} from "reactstrap";
import * as actions from "../../actions/actions";
import Spin from "../../assets/img/Spin.svg";
import { DASHBOARD, FORM, SERVICE, POLICY } from "../../constants";
import { isEqual } from "lodash";
import IconApointment from "../../assets/img/icon-apointment.png";
import IconWalkIn from "../../assets/img/icon-walkin.png";
import IconWalkIn1 from "../../assets/img/icon-walkin-1.png";
import Coupon from "../../assets/img/coupon.png";
import { formatPhoneNumber } from "react-phone-number-input";
import SockJS from "sockjs-client";
import { over } from "stompjs";
import moment from "moment";
import htmlToImage from "html-to-image";
import { uid } from "rand-token";
import PhoneInput from "react-phone-input-2";

var stompClient = null;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectForm: FORM,
      isSubmitForm: false,
      isApointment: false,
      name: "",
      phone: "",
      keyword: "",
      serviceIds: [],
      couponNumber: "",
      errors: {},
      isOpenCheck: false,
      isOpen: false,
      isLoadingSubmit: false,
      billNumber: 0,
      loyaltyPoint: 0,
      uuid: "",
      isIgnoreService: true,
      isCheckList: [
        {
          name: "form-field-1",
          checked: true,
        },
        {
          name: "form-field-2",
          checked: true,
        },
        {
          name: "form-field-3",
          checked: true,
        },
        {
          name: "form-field-4",
          checked: true,
        },
        {
          name: "form-field-5",
          checked: true,
        },
      ],
      message: "Please wait a moment for arranging a Technichian for you",
    };
  }

  componentDidMount() {
    this.props.onInit().then((res) => {
      if (res && res.data && res.data.code && res.data.code === 200) {
        this.setState({ isIgnoreService: res.data.payload });
      }
    });
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    const listPath = pathname.split("/");
    const apiKey = listPath[1];
    const uuid = uid(16);
    this.setState({ uuid });
    var socket = new SockJS(
      "https://api.ontiloo.com/api/v1/gs-guide-websocket"
    );
    stompClient = over(socket);
    stompClient.connect({}, () => {
      stompClient.subscribe("/topic/checkin/" + apiKey, (res) => {
        this.getReponseRealtime(res);
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectForm, phone } = this.state;
    if (!isEqual(selectForm, prevState.selectForm)) {
      if (selectForm == SERVICE || selectForm == POLICY) window.scrollTo(0, 0);
      else window.scrollTo(0, document.body.scrollHeight);
    }
    if (!isEqual(phone, prevState.phone)) console.log(phone);
  }

  getReponseRealtime = (res) => {
    const oldUuid = this.state.uuid;
    if (res && res.body) {
      const response = JSON.parse(res.body);
      const {
        body: { code, message, payload: { billNumber,loyaltyPoint, uuid } = {} } = {},
      } = response;
      if (uuid == oldUuid) {
        this.setState({
          isOpen: true,
          isLoadingSubmit: false,
          billNumber,
          loyaltyPoint,
          isSubmitError: code !== 200,
          message:
            code === 200
              ? "Please wait a moment for arranging a Technichian for you"
              : "Please wait a moment for arranging a Technichian for you",
        });
      }
    }
    this.setState({ isLoadingSubmit: false });
  };

  onChange = ({ target: { name, value } }) => this.setState({ [name]: value });

  setPhone = (phone) => {
    // const phone = value + "";
    // console.log(value, phone, phone.includes("+1") ? phone : "+1" + phone);
    // if (!value || value == "+1") this.setState({ phone: "" });
    // else if (phone)
    //   this.setState({ phone: phone.includes("+1") ? phone : "+1" + phone });
    this.setState({ phone });
  };

  onSelectService = () => {
    const { name, phone, isIgnoreService } = this.state;
    if (!name || !phone) {
      const errors = {};
      if (!name) errors.name = true;
      if (!phone) errors.phone = true;
      this.setState({
        errors,
      });
    } else {
      if (!isIgnoreService) this.setState({ selectForm: POLICY });
      else
        this.setState({
          errors: {},
          isSubmitForm: true,
          selectForm: SERVICE,
        });
    }
  };

  onSubmit = (base64 = null) => {
    this.setState({ isLoadingSubmit: true });
    const { name, phone, isApointment, serviceIds, uuid } = this.state;
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    const listPath = pathname.split("/");

    const payload = {
      name,
      phone,
      type: isApointment ? 1 : 2,
      serviceIds,
      apiKey: listPath[1],
      // base64,
      uuid,
    };

    stompClient.send("/socket/checkin", {}, JSON.stringify({ payload }));
  };

  closeModal = () => {
    this.setState({
      name: "",
      phone: "",
      serviceIds: [],
      isApointment: true,
      couponNumber: "",
      isSubmitForm: false,
      isOpen: false,
    });
  };

  selectService = (id) => () => {
    const { serviceIds } = this.state;
    this.setState({
      serviceIds:
        serviceIds.indexOf(id) > -1
          ? serviceIds.filter((service) => service != id)
          : [...serviceIds, id],
    });
  };

  removeService = (id) => () =>
    this.setState({
      serviceIds: this.state.serviceIds.filter((service) => service != id),
    });

  getBase64AndSubmit = () => {
    this.setState({ isLoadingSubmit: true });

    var node = document.getElementById("form-policy");
    setTimeout(() => {
      if (node) {
        htmlToImage
          .toPng(node)
          .then(() => {
            htmlToImage
              .toPng(node)
              .then((dataUrl) => {
                this.onSubmit(dataUrl);
              })
              .catch(function (error) {
                console.error("oops, something went wrong!", error);
              });
          })
          .catch(function (error) {
            this.setState({ isLoadingSubmit: false });
            console.error("oops, something went wrong!", error);
          });
      }
    }, 500);
  };

  changeCheckList = ({ target: { name, checked } }) => {
    const { isCheckList } = this.state;

    const isExist = isCheckList.find((checkItem) => checkItem.name === name);
    this.setState({
      isCheckList: isExist
        ? [
            ...isCheckList.map((item) =>
              item.name === name ? { ...item, checked } : item
            ),
          ]
        : [...isCheckList, { name, checked }],
    });
  };

  findCheckListByName = (name) => {
    const { isCheckList } = this.state;
    const checkItem = isCheckList.find((item) => item.name == name);
    return checkItem ? checkItem.checked : false;
  };

  render() {
    const {
      isApointment,
      name,
      phone,
      selectForm,
      isSubmitForm,
      errors,
      serviceIds,
      keyword,
      couponNumber,
      isOpenCheck,
      isOpen,
      isLoadingSubmit,
      billNumber,
      loyaltyPoint,
      isCheckList,
      isIgnoreService,
      isSubmitError,
      message,
    } = this.state;

    const {
      isLoading,
      serviceList,
      shopDetail,
      serviceCategoryList,
    } = this.props;

    let isDisable = 0;
    isCheckList.forEach((item) => {
      if (item.checked) isDisable++;
    });

    return (
      <>
        <div className="dashboard-form">
          <div
            className={`loading-popup ${
              !!isLoading || isLoadingSubmit ? "show" : ""
            }`}
          >
            <img src={Spin} />
          </div>
          {selectForm !== POLICY && (
            <div className="dashboard-form-1">
              <div className="triangle-form"></div>
              <div className="header-form">
                {/* <span><img src={`data:image;base64,${shopDetail.avatar}`} /></span> */}
                <div className="header-meta">
                  <h5>{shopDetail.companyName}</h5>
                  <p className="shopInfo">
                    {shopDetail.address} {shopDetail.state || ""},{" "}
                    {shopDetail.city || ""} {shopDetail.zip || ""}{" "}
                  </p>
                  <p className="shopInfo">
                    Phone:{" "}
                    {formatPhoneNumber(
                      shopDetail.phone && shopDetail.phone.includes("+1")
                        ? shopDetail.phone
                        : "+1" + shopDetail.phone
                    )}
                  </p>
                </div>
              </div>
              {selectForm == FORM && (
                <>
                  <div className="body-form">
                    {!isSubmitForm && (
                      <>
                        <h4>Select Client Type</h4>
                        <div className="group-button">
                          <Button
                            onClick={() =>
                              this.setState({ isApointment: true })
                            }
                            className={isApointment ? "isSelect" : ""}
                          >
                            <span>
                              <img src={IconApointment} />
                            </span>{" "}
                            Apointment
                          </Button>
                          <Button
                            onClick={() =>
                              this.setState({ isApointment: false })
                            }
                            className={!isApointment ? "isSelect" : ""}
                          >
                            <span>
                              <img
                                src={!isApointment ? IconWalkIn : IconWalkIn1}
                              />
                            </span>{" "}
                            {"Walk-in"}
                          </Button>
                        </div>
                      </>
                    )}
                    <h4>
                      {isApointment ? "Apointment " : "Walk-in"} Infomation
                    </h4>
                    <FormGroup className="form-input">
                      <Label>Your Name</Label>
                      <div className="group-input">
                        <span>
                          <i
                            className="fa fa-user-circle"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <Input
                          disabled={isSubmitForm}
                          name="name"
                          value={name}
                          onChange={this.onChange}
                          placeholder="Your Name"
                        />
                      </div>
                      {errors && errors.name && (
                        <Label className="error-form">
                          Please enter your name
                        </Label>
                      )}
                    </FormGroup>
                    <FormGroup className="form-input">
                      <Label>Phone Number</Label>
                      <div className="group-input">
                        <span className="icon-phone">
                          <i className="fa fa-mobile" aria-hidden="true"></i>
                        </span>
                        <PhoneInput
                          className="form-control"
                          country="us"
                          disabled={isSubmitForm}
                          onlyCountries={["us"]}
                          value={phone}
                          name="phone"
                          autoComplete="off"
                          onChange={this.setPhone}
                          disableCountryCode
                          specialLabel=""
                          placeholder="Phone Number"
                        />
                      </div>
                      {errors && errors.phone && (
                        <Label className="error-form">
                          Please enter your phone number
                        </Label>
                      )}
                    </FormGroup>
                    {isSubmitForm && (
                      <>
                        <div className="coupon-check">
                          <div className="group-check">
                            <span>
                              <img src={Coupon} />
                            </span>
                            <p>
                              Have a coupon?{" "}
                              <span
                                onClick={() =>
                                  this.setState({
                                    isOpenCheck: !this.state.isOpenCheck,
                                  })
                                }
                              >
                                Tap to enter your code
                              </span>
                            </p>
                          </div>
                          <div
                            className={`input-check ${
                              isOpenCheck ? "open" : ""
                            }`}
                          >
                            <Input
                              onChange={this.onChange}
                              value={couponNumber}
                              name="couponNumber"
                              placeholder="Enter your coupon number"
                            />
                          </div>
                        </div>
                        <h4>Service Infomation</h4>
                        {serviceList &&
                          serviceList.map(
                            (service) =>
                              serviceIds.indexOf(service.id) > -1 && (
                                <div
                                  className="service-item"
                                  key={service.id}
                                  onClick={this.removeService(service.id)}
                                >
                                  <div className="item-meta">
                                    <img src={service.urlImage} />
                                  </div>
                                  <div className="item-content">
                                    <p>{service.serviceName}</p>
                                    <span>${service.price}</span>
                                  </div>
                                  <span>
                                    <i
                                      className="fa fa-minus-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div>
                              )
                          )}
                        <button
                          className="btn-add"
                          onClick={() => this.setState({ selectForm: SERVICE })}
                        >
                          <span>+</span> Add another services
                        </button>
                      </>
                    )}
                  </div>
                  <Button
                    disabled={
                      !isSubmitForm ? !name || !phone : !serviceIds.length
                    }
                    className="btn-submit"
                    onClick={
                      !isSubmitForm
                        ? this.onSelectService
                        : () => this.setState({ selectForm: POLICY })
                    }
                  >
                    {!isSubmitForm ? "Submit" : "Ready to serve"}
                  </Button>
                </>
              )}
              {selectForm == SERVICE && (
                <>
                  <div
                    className={`form-service ${
                      !!serviceIds.length ? "isSelected" : ""
                    }`}
                  >
                    <h4>Chose a Services</h4>
                    <div className="search-service">
                      <Input
                        onChange={this.onChange}
                        name="keyword"
                        value={keyword}
                        placeholder="Search services"
                      />
                      <span>
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </span>
                    </div>
                    {serviceCategoryList.map((category) => (
                      <div className="category-sv-item" key={category.id}>
                        <Button color="primary" id={`toggler-${category.id}`}>
                          {category.categoryName}
                          <span>
                            <i
                              className={"fa fa-plus-square-o"}
                              aria-hidden="true"
                            ></i>
                          </span>
                        </Button>
                        <UncontrolledCollapse
                          toggler={`#toggler-${category.id}`}
                          defaultOpen={true}
                        >
                          <Card>
                            {serviceList &&
                              serviceList
                                .filter(
                                  (service) =>
                                    service.serviceName
                                      .toLowerCase()
                                      .includes(keyword.toLowerCase()) &&
                                    service.catId == category.id
                                )
                                .map((service) => {
                                  const isSelect =
                                    serviceIds.indexOf(service.id) > -1;
                                  return (
                                    <div
                                      className={`service-item ${
                                        isSelect ? "selected" : ""
                                      }`}
                                      key={service.id}
                                      onClick={this.selectService(service.id)}
                                    >
                                      <div className="item-meta">
                                        <img src={service.urlImage} />
                                      </div>
                                      <div className="item-content">
                                        <p>{service.serviceName}</p>
                                        <span>${service.price}</span>
                                      </div>
                                      <span>
                                        <i
                                          className={
                                            isSelect
                                              ? "fa fa-minus-square-o"
                                              : "fa fa-plus-square-o"
                                          }
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </div>
                                  );
                                })}
                          </Card>
                        </UncontrolledCollapse>
                      </div>
                    ))}
                  </div>
                  {!!serviceIds.length && (
                    <div className="btn-ready">
                      <span>{serviceIds.length} services has chosen</span>
                      <Button
                        // onClick={() =>
                        //   this.setState({ selectForm: FORM, keyword: "" })
                        // }
                        onClick={this.onSubmit}
                      >
                        Ready to serve
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {selectForm == POLICY && (
            <div className="group-policy">
              <div id="form-policy">
                <h5>COVID-19 Pandemic Nail Treatment Form</h5>
                <p>
                  I {name}, knowingly and willingly consent to have nail
                  treatment during the COVID-19 pandemic.
                </p>
                <CustomInput
                  onChange={this.changeCheckList}
                  type="checkbox"
                  checked={this.findCheckListByName("form-field-1")}
                  name="form-field-1"
                  id="form-field-1"
                  label="I understand the COVID-19 virus has a long incubation period during carriers of the virus may not show symptoms and still be highly contagious.  It is impossible to determine who has it and who does not given the current limits in virus testing"
                />
                <CustomInput
                  onChange={this.changeCheckList}
                  type="checkbox"
                  checked={this.findCheckListByName("form-field-2")}
                  name="form-field-2"
                  id="form-field-2"
                  label="I understand that due to the frequency of visits of other clients, the characteristics of the virus, and the characteristics of nail treatments, that I have an elevated risk of contracting the virus simply by being in a salon."
                />
                <CustomInput
                  onChange={this.changeCheckList}
                  type="checkbox"
                  checked={this.findCheckListByName("form-field-3")}
                  name="form-field-3"
                  id="form-field-3"
                  label="I confirm that I am not presenting any of the following symptoms of COVID-19 listed below"
                />
                <ul>
                  <li>Fever – Temperature_(100 F_ degree</li>
                  <li>Shortness of Breath</li>
                  <li>Loss of Sense of Taste or Smell</li>
                  <li>Dry Cough</li>
                  <li>Running Nose</li>
                  <li>Sore Throat</li>
                </ul>
                <CustomInput
                  onChange={this.changeCheckList}
                  type="checkbox"
                  checked={this.findCheckListByName("form-field-4")}
                  name="form-field-4"
                  id="form-field-4"
                  label="To prevent the spread of contagious viruses and to help protect each other, I understand that I will have to follow the salon’s strict guidelines"
                />
                <CustomInput
                  onChange={this.changeCheckList}
                  type="checkbox"
                  checked={this.findCheckListByName("form-field-5")}
                  name="form-field-5"
                  id="form-field-5"
                  label="I understand that air travel significantly increases my risk of contracting and transmitting the COVID-19 virus.  And I understand that the CDC, OSHA and (state cua salon) Board of Cosmetology and Barbers recommend social distancing of at least 6 feet"
                />
                <ul>
                  <li>
                    <p>
                      I verify that I have not traveled outside the United
                      States in the past 14 days to countries that have been
                      affected by COVID-19
                    </p>
                  </li>
                  <li>
                    <p>
                      I verify that I have not traveled domestically within the
                      United States by commercial airline, bus, or train within
                      the past 14 days.
                    </p>
                  </li>
                </ul>
                <p>Name: {name}</p>
                <p>Date: {moment().format("MMM, DD YYYY")}</p>
              </div>
              <button disabled={isDisable != 5} onClick={this.onSubmit}>
                Agree
              </button>
            </div>
          )}
        </div>
        <Modal
          isOpen={isOpen}
          toggle={() => {}}
          className="modal-success"
          centered
        >
          <ModalBody>
            {isSubmitError ? (
              <h5 className="error-submit">
                <i className="fa fa-times-circle" aria-hidden="true"></i>{" "}
                Oops...
              </h5>
            ) : (
              <div>
                <h5>Your waiting #: {billNumber}</h5>
                <h6>Loyalty point: {loyaltyPoint} </h6>
              </div>
            
            )}
            {!isSubmitError && <h4>THANK YOU</h4>}
            <p>{message}</p>
            {/* <Button onClick={this.closeModal}>Back to Home</Button> */}
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.dashboard.isLoading,
    serviceList: state.dashboard.serviceList,
    serviceCategoryList: state.dashboard.serviceCategoryList,
    shopDetail: state.dashboard.shopDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: async () => {
      dispatch(actions.callAPI(DASHBOARD, "getServiceList"));
      dispatch(actions.callAPI(DASHBOARD, "getShopDetail"));
      dispatch(actions.callAPI(DASHBOARD, "getServiceCategoryList"));
      return await dispatch(actions.callbackAPI(DASHBOARD, "checkShowService"));
    },
    onSubmit: async (payload) => {
      return await dispatch(
        actions.callbackAPI(DASHBOARD, "submitIcheckin", payload)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
