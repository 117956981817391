import axios from 'axios';
import { API_URL } from './Config';

const pathname = window.location.pathname
let keys = pathname.split('/')
let ApiKey = keys[1]

export default function callApi(specType, endpoint, method = 'POST', body) {
  return axios({
    headers: {
      'x-api-key': ApiKey
    },
    method: method,
    url: `${API_URL}/api/v1/${specType}/${endpoint}`,
    data: body,
  })
    .catch((error) => {
      if (error && error.response && error.response.data.code && error.response.data.code) {
        return { data: { code: error.response.data.code, message: error.response.data.message } }
      }
      return false;
    })

}
