
import * as types from '../../actions/Dashboard/ActionTypes'
const initialState = {

  isLoading: 0,
  serviceList: [],
  serviceCategoryList: [],
  shopDetail: {}
}

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case types.ISLOADING:
      return {
        ...state,
        isLoading: action.state ? state.isLoading + 1 : state.isLoading - 1
      }
    case types.SERVICE_LIST:
      return {
        ...state,
        serviceList: action.list
      }
    case types.SERVICE_CATEGORY_LIST:
      return {
        ...state,
        serviceCategoryList: action.list
      }
    case types.SHOP_DETAIL:
      return {
        ...state,
        shopDetail: action.detail
      }
    default:
      return state
  }
}