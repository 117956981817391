import apiCaller from '../../_helper/apiCaller'
import * as types from './ActionTypes'

export function getServiceList() {
  return dispatch => {
    return apiCaller('service', 'list', 'GET').then(res => {
      if (res && res.data && res.data.code && res.data.code === 200) {
        dispatch(setServiceList(res.data.payload))
        return true
      }
      return false
    })
  }
}

export function getServiceCategoryList() {
  return dispatch => {
    return apiCaller('service/category', 'list', 'GET').then(res => {
      if (res && res.data && res.data.code && res.data.code === 200) {
        dispatch(setServiceCateogryList(res.data.payload))
        return true
      }
      return false
    })
  }
}

export function getShopDetail() {
  return dispatch => {
    return apiCaller('shop', 'detail', 'GET').then(res => {
      if (res && res.data && res.data.code && res.data.code === 200) {
        dispatch(setShopDetail(res.data.payload))
        return true
      }
      return false
    })
  }
}

export function submitIcheckin(payload) {
  return apiCaller('customer', 'checkin', 'POST', { payload }).then(res => {
    return res && res.data && res.data.code && res.data.code === 200
  })
}

export function checkShowService() {
  return apiCaller('service', 'checkShowService', 'GET').then(res => {
    return res 
  })
}

export const setServiceList = list => {
  return {
    type: types.SERVICE_LIST,
    list
  }
}

export const setServiceCateogryList = list => {
  return {
    type: types.SERVICE_CATEGORY_LIST,
    list
  }
}

export const setShopDetail = detail => {
  return {
    type: types.SHOP_DETAIL,
    detail
  }
}