import React, { Component } from 'react'
import { Link } from "react-router-dom";

export default function PageError() {
  return (
    <div className="banner-page">
      <h4>Page not found</h4>
      <Link to="/"><span><i className="fa fa-undo" aria-hidden="true"></i></span> <span>Back to Home</span></Link>
    </div>
  );
}



