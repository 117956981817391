import React, { Component } from 'react'
import './App.scss';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Page404 from './Views/Pages/Page404/page404'
import Dashboard from './Views/Dashboard/dashboard'
import 'bootstrap/dist/css/bootstrap.min.css'


const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);


export default class App extends Component {
  
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route path="/404" name="Page not found" component={Page404}></Route>
            <Route path="/" name="Register" render={props => <Dashboard {...props} /> } ></Route>
            <Route path="/:id" exact name="Register" render={props => <Dashboard {...props} /> } ></Route>
            <Redirect to='/404' />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}